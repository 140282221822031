(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("modularConfigurationModalController", modularConfigurationModalController);

    function modularConfigurationModalController($mdDialog, addonName, themesService, $translate, $http) {
        var vm = this;

        vm.addonName = addonName;
        vm.activeSections = [];
        vm.bannerImage = "";
        vm.addonEnabled = false; 
        vm.cancel = cancel;
        vm.save = save;

        activate();

        function activate() {
            initializeSettings(addonName);
            setBannerImage();
            fetchSettings();
        }

        function initializeSettings(addonName) {
            switch (addonName) {
                case "collabora":
                    vm.activeSections = [
                        {
                            title: $translate.instant('COLLABORAONLINE'),
                            settings: [
                                { type: "input", label: $translate.instant('ONLYOFFICE_SERVER_URL'), value: "" },
                                { type: "input", label: $translate.instant('MAX_CONNECTIONS_ZERO_UNLIMITED'), value: 0 },
                                { type: "input", label: $translate.instant('MAX_CONNECTIONS_PER_USER_ZERO_UNLIMITED'), value: 0 }
                            ]
                        }
                    ];
                    break;

                case "onlyOffice":
                    vm.activeSections = [
                        {
                            title: $translate.instant('ONLYOFFICE'),
                            settings: [
                                { type: "input", label: $translate.instant('ONLYOFFICE_SERVER_URL'), value: "" },
                                { type: "input", label: $translate.instant('MAX_CONNECTIONS_ZERO_UNLIMITED'), value: 0 },
                                { type: "input", label: $translate.instant('MAX_CONNECTIONS_PER_USER_ZERO_UNLIMITED'), value: 0 }
                            ]
                        }
                    ];
                    break;

                default:
                    console.error(`No settings available for addon: ${addonName}`);
                    vm.activeSections = [];
                    break;
            }
        }

        function setBannerImage() {
            vm.bannerImage = `img/MarketPlace/${vm.addonName}_banner.webp`;
        }

        function fetchSettings() {
            var apiUrl;
            switch (vm.addonName) {
                case "collabora":
                    apiUrl = "/api/v1/marketplace/GetCollaboraOnlineSettings";
                    break;
                case "onlyOffice":
                    apiUrl = "/api/v1/marketplace/GetOnlyOfficeSettings";
                    break;
                default:
                    console.error(`No API endpoint available for addon: ${vm.addonName}`);
                    return;
            }

            $http.post(apiUrl, {})
                .then(function (response) {
                    var settings = response.data;

                    vm.addonEnabled = settings.enabled || false;

                    populateSettings(settings);
                })
                .catch(function (error) {
                    console.error(`Failed to fetch settings for addon: ${vm.addonName}`, error);
                });
        }

        function populateSettings(settings) {
            vm.activeSections.forEach(section => {
                section.settings.forEach(setting => {
                    switch (setting.label) {
                        case $translate.instant('ONLYOFFICE_SERVER_URL'):
                            setting.value = settings.serverUrl || settings.url;
                            break;
                        case $translate.instant('MAX_CONNECTIONS_ZERO_UNLIMITED'):
                            setting.value = settings.maxConnections || 0;
                            break;
                        case $translate.instant('MAX_CONNECTIONS_PER_USER_ZERO_UNLIMITED'):
                            setting.value = settings.maxConnectionsPerUser || 0;
                            break;
                    }
                });
            });
        }

        function cancel() {
            $mdDialog.cancel();
        }

        function save() {
            var settings = {};
            switch (vm.addonName) {
                case "collabora":
                    settings.wopi = {};
                    vm.activeSections.forEach(section => {
                        if (section.title === $translate.instant('COLLABORAONLINE')) {
                            section.settings.forEach(setting => {
                                settings.wopi.enabled = vm.addonEnabled;
                                if (setting.label === $translate.instant('ONLYOFFICE_SERVER_URL')) settings.wopi.serverUrl = setting.value;
                                if (setting.label === $translate.instant('MAX_CONNECTIONS_ZERO_UNLIMITED')) settings.wopi.maxConnections = setting.value;
                                if (setting.label === $translate.instant('MAX_CONNECTIONS_PER_USER_ZERO_UNLIMITED')) settings.wopi.maxConnectionsPerUser = setting.value;
                            });
                        }
                    });
                    break;

                case "onlyOffice":
                    settings.onlyOffice = {};
                    vm.activeSections.forEach(section => {
                        if (section.title === $translate.instant('ONLYOFFICE')) {
                            section.settings.forEach(setting => {
                                settings.onlyOffice.enabled = vm.addonEnabled;
                                if (setting.label === $translate.instant('ONLYOFFICE_SERVER_URL')) settings.onlyOffice.serverUrl = setting.value;
                                if (setting.label === $translate.instant('MAX_CONNECTIONS_ZERO_UNLIMITED')) settings.onlyOffice.maxConnections = setting.value;
                                if (setting.label === $translate.instant('MAX_CONNECTIONS_PER_USER_ZERO_UNLIMITED')) settings.onlyOffice.maxConnectionsPerUser = setting.value;
                            });
                        }
                    });
                    break;

                default:
                    console.error(`No settings to save for addon: ${vm.addonName}`);
                    break;
            }

            $mdDialog.hide(settings);
        }
    }
})();