(function () {
	"use strict";

	angular
		.module("smartertools")
		.service("customNgMessages", customNgMessages);

	function customNgMessages($templateCache) {
		var vm = this;
		vm.ensureTemplateCreated = ensureTemplateCreated;

		vm.ensureTemplateCreated();

		function ensureTemplateCreated() {
			if (vm.created)
				return;
			vm.created = true;
			$templateCache.put('error-messages',
				'<div ng-message="required">{{::"VALIDATION_REQUIRED" | translate}}</div>' +
				'<div ng-message="email">{{::"VALIDATION_INVALID_EMAIL_ADDRESS" | translate}}</div>' +
				'<div ng-message="url">{{::"VALIDATION_INVALID_URL" | translate}}</div>' +
				'<div ng-message="[\'min\', \'max\']">{{::"VALIDATION_INVALID_NUMERIC_RANGE_GENERIC" | translate}}</div>' +
				'<div ng-message="validateIpAddress">{{::"VALIDATION_INVALID_IP_ADDRESS" | translate}}</div>' +
				'<div ng-message="validateIpAddressRange">{{::"VALIDATION_INVALID_IP_ADDRESS_RANGE" | translate}}</div>' +
				'<div ng-message="validateIpAddressWildcard">{{::"VALIDATION_INVALID_IP_ADDRESS" | translate}}</div>' +
				'<div ng-message="validateDomainName">{{::"VALIDATION_INVALID_DOMAIN_NAME" | translate}}</div>' +
				'<div ng-message="validateDomainNameList">{{::"VALIDATION_INVALID_DOMAIN_NAME_LIST" | translate}}</div>' +
				'<div ng-message="validateDomainOrEmailList">{{::"VALIDATION_INVALID_DOMAIN_NAME_OR_EMAIL_LIST" | translate}}</div>' +
				'<div ng-message="validateDomainOrEmail">{{::"VALIDATION_INVALID_DOMAIN_OR_EMAIL" | translate}}</div>' +
				'<div ng-message="validateRblHost">{{::"VALIDATION_INVALID_DOMAIN_NAME" | translate}}</div>' +
				'<div ng-message="requiresNoUnc">{{::"VALIDATION_INVALID_FOLDER_PATH" | translate}}</div>' +
				'<div ng-message="requiresNoUncAllowRoot">{{::"VALIDATION_INVALID_FOLDER_PATH" | translate}}</div>' +
				'<div ng-message="invalidFolderCharacters">{{::"VALIDATION_INVALID_FOLDER_CHARACTERS" | translate}}</div>'+
				'<div ng-message="invalidFolderCharactersSlashes">{{::"VALIDATION_INVALID_FOLDER_CHARACTERS" | translate}}</div>' +
				'<div ng-message="reservedFolderName">{{::"VALIDATION_RESERVED_FOLDER_NAME" | translate}}</div>'+
				'<div ng-message="validateHexColor">{{::"VALIDATION_INVALID_HEX_COLOR" | translate}}</div>' +
				'<div ng-message="checkPasswords">{{::"VALIDATION_PASSWORD_MISMATCH" | translate}}</div>' +
				'<div ng-message="checkPasswordsOptional">{{::"VALIDATION_PASSWORD_MISMATCH" | translate}}</div>' +
				'<div ng-message="checkPasswordsNotMatch">{{::"VALIDATION_PASSWORD_CANT_MATCH" | translate}}</div>' +
				'<div ng-message="validateEmailList">{{::"VALIDATION_INVALID_EMAIL_LIST" | translate}}</div>' +
				'<div ng-message="validateNumberList">{{::"VALIDATION_INVALID_NUMBER_LIST" | translate}}</div>' +
				'<div ng-message="number">{{::"VALIDATION_INVALID_NUMBER" | translate}}</div>' +
				'<div ng-message="validateEmail">{{::"VALIDATION_INVALID_EMAIL_ADDRESS" | translate}}</div>' +
				'<div ng-message="validateFullyQualifiedPath">{{::"VALIDATION_GENERIC_INVALID_FOLDER_PATH" | translate}}</div>' +
				'<div ng-message="validateFullyQualifiedDomainPath">{{::"VALIDATION_GENERIC_INVALID_FOLDER_PATH" | translate}}</div>' +
				'<div ng-message="validateAttachDomain">{{::"VALIDATION_GENERIC_INVALID_FOLDER_OR_DOMAIN" | translate}}</div>' +
				'<div ng-message="validateLinkExists">{{::"VALIDATION_LINK_DOES_NOT_EXIST" | translate}}</div>' +
				'<div ng-message="LICENSE_KEY_FOR_WRONG_VERSION">{{::"LICENSE_KEY_FOR_WRONG_VERSION" | translate}}</div>' +
				'<div ng-message="LICENSE_INVALID_CREDENTIALS">{{::"LICENSE_INVALID_CREDENTIALS" | translate}}</div>' +
				'<div ng-message="LICENSE_INVALID_LICENSE_KEY">{{::"LICENSE_INVALID_LICENSE_KEY" | translate}}</div>' +
				'<div ng-message="LICENSE_EXPIRED">{{::"LICENSE_EXPIRED" | translate}}</div>' +
				'<div ng-message="LICENSE_NO_MORE_ACTIVATIONS">{{::"LICENSE_NO_MORE_ACTIVATIONS" | translate}}</div>' +
				'<div ng-message="LICENSE_OVER_SERVER_LIMIT">{{::"LICENSE_OVER_SERVER_LIMIT" | translate}} {{::"LICENSE_OVER_LIMIT_2" | translate}}</div>' +
				'<div ng-message="LICENSE_OVER_SITE_LIMIT">{{::"LICENSE_OVER_SITE_LIMIT" | translate}} {{::"LICENSE_OVER_LIMIT_2" | translate}}</div>' +
				'<div ng-message="LICENSE_OVER_USER_LIMIT">{{::"LICENSE_OVER_USER_LIMIT" | translate}} {{::"LICENSE_OVER_LIMIT_2" | translate}}</div>' +                 
				'<div ng-message="LICENSE_KEY_FOR_WRONG_PRODUCT">{{::"LICENSE_KEY_FOR_WRONG_PRODUCT" | translate}}</div>' +
				'<div ng-message="LICENSE_PROBLEM_ACTIVATING">{{::"LICENSE_PROBLEM_ACTIVATING" | translate}}</div>' +
				'<div ng-message="validateEmailUsername">{{::"VALIDATION_INVALID_EMAIL_USERNAME" | translate}}</div>' +
				'<div ng-message="validateUsernameNoDomain">{{::"VALIDATION_INVALID_USERNAME_CONTAINS_DOMAIN" | translate}}</div>' +
				'<div ng-message="validateUsernameOctetLength">{{::"VALIDATION_INVALID_MAX_LENGTH" | translate : { max: 64 } }}</div>' +
				'<div ng-message="validateReservedWord">{{::"VALIDATION_INVALID_RESERVED_WORD" | translate}}</div>' +
				'<div ng-message="validateEmailUsernameInvalidCharacters">{{::"VALIDATION_INVALID_EMAIL_USERNAME_INVALID_CHARS" | translate}}</div>' +
				'<div ng-message="validateEmailExistence">{{::"VALIDATION_EMAIL_DOESNT_EXIST" | translate}}</div>' + 
				'<div ng-message="validateUrl">{{::"VALIDATION_INVALID_URL" | translate}}</div>' +
				'<div ng-message="validateUserExists">{{::"VALIDATION_USER_DOES_NOT_EXIST" | translate}}</div>' +
				'<div ng-message="md-require-match">{{::"VALIDATION_USER_DOES_NOT_EXIST" | translate }}</div>' +
				'<div ng-message="validateAliasName">{{::"VALIDATION_INVALID_ALIAS_NAME" | translate }}</div>' +
				'<div ng-message="validateEmailAliasDoesNotExist">{{::"VALIDATION_EMAIL_ALIAS_NAME_ALREADY_EXISTS" | translate }}</div>' +
				'<div ng-message="validateSysadminDoesNotExists">{{::"VALIDATION_SYSTEM_ADMIN_USERNAME_ALREADY_EXISTS" | translate }}</div>'+
				'<div ng-message="validateCertificateCsr">{{::"VALIDATE_CERTIFICATE_CSR" | translate }}</div>'+
				'<div ng-message="validatePathConversion">{{::"VALIDATE_INVALID_PATH_PERMISSIONS" | translate }}</div>'


				);
		}
	}

})();
