(function () {
    'use strict';

    angular
        .module('smartermail')
        .controller('meetingsSectionController', meetingsSectionController);

    function meetingsSectionController($rootScope, $scope, $compile, $translate, $filter, $http, $timeout, $mdDialog, meetingWorkspaces,
        treeState, successHandling, coreDataSettings, claimsService, popupService, userTimeService) {
        var vm = this;
        vm.hasFilter = false;
        vm.loading = true;
        vm.meetings = null;
        vm.search = '';
        vm.selectMode = false;
        vm.selectedCards = [];
        vm.sortBy = 'title';
        vm.sortReverse = false;
        vm.treeExpanded = treeState.isExpanded;
        vm.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        vm.viewsTreeController = {};
        vm.viewsTree = {
            data: [],	// Used by tree directive
            map: [],	// Reference to treeData nodes, used to quickly find and change values
            selectedBranchData: {}
        };

        // Functions
        vm.deleteWithConfirmation = deleteWithConfirmation;
        vm.copyMeetingLink = copyMeetingLink;
        vm.doSearch = doSearch;
        vm.isCardSelected = isCardSelected;
        vm.newMeeting = newMeeting;
        vm.openCard = openCard;
        vm.onMouseUp = onMouseUp;
        vm.refresh = refresh;
        vm.selectAll = selectAll;
        vm.selectNone = function () { vm.selectedCards = []; };
        vm.setSelectMode = setSelectMode;
        vm.onBranchChanged = onBranchChanged;
        vm.unarchiveMeeting = unarchiveMeeting;

        // Startup
        activate();

        // Implementation
        function activate() {
            loadViewsTree();

            window.addEventListener("resize", calcHeight, true);

            $scope.$on("$destroy", onDestroy);
            $scope.$on("meetings.refresh", onMeetingsRefresh);
            $scope.$on("treeState:stateChange", onTreeStateChanged);

            $timeout(function () {
                vm.viewsTreeController.select_first_branch();
                refresh();
            });
            
            if (!claimsService.impersonating() && coreDataSettings.userSettings.seenWhatsNew) {
                var keyExist = ("onlineMeeting" in coreDataSettings.userSettings.seenWhatsNew);
                if (keyExist) {
                    var versionOverride = localStorage.getItem("FeatureVersionOverride");
                    var shouldShow = versionOverride === null ? stProductVersion.split('.')[2] > coreDataSettings.userSettings.seenWhatsNew["onlineMeeting"] : true;
                    if (shouldShow) {
                        var route = `~/api/v1/settings/new-features/OnlineMeeting${versionOverride === null ? "" : "/" + versionOverride}`;
                        $http.get(route).then(onFeaturesLoaded, function () { });
                    }
                } else {
                    $http.get('~/api/v1/settings/new-features/OnlineMeeting').then(onFeaturesLoaded, function () { });
                }
            }

            userTimeService.init().then(function () {
                vm.userTimeZone = userTimeService.userTimeZone.location;
            })

        }

        function onFeaturesLoaded(result) {
            var newItems = result.data.newFeatures;
            if (newItems.length > 0) {
                $rootScope.$broadcast("user-settings:changed");
                if (newItems.length > 4 && window.innerWidth > 736) {
                    $mdDialog.show({
                        locals: { items: newItems },
                        controller: "whatsNewDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "~/interface/app/shared/modals/whats-new-double.dlg.html",
                        clickOutsideToClose: false
                    }).then(function () { }, function () { });
                }
                else {
                    $mdDialog.show({
                        locals: { items: newItems },
                        controller: "whatsNewDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "~/interface/app/shared/modals/whats-new-narrow.dlg.html",
                        clickOutsideToClose: false
                    }).then(function () { }, function () { });
                }
            }
        }

        function loadViewsTree() {
            vm.viewsTree.data = [];
            vm.viewsTree.data.push({
                label: $translate.instant('MY_MEETINGS'),
                data: {
                    id: "MY_MEETINGS",
                    key: "MY_MEETINGS"
                },
                children: [
                    {
                        label: $translate.instant("ARCHIVED"),
                        data: {
                            id: "MY_ARCHIVED",
                            key: "MY_ARCHIVED"
                        }
                    }]
            });
            vm.viewsTree.data.push({
                label: $translate.instant('PARTICIPATED_MEETINGS'),
                data: {
                    id: "PARTICIPATED_MEETINGS",
                    key: "PARTICIPATED_MEETINGS"
                },
                children: [
                    {
                        label: $translate.instant("ARCHIVED"),
                        data: {
                            id: "PARTICIPATED_ARCHIVED",
                            key: "PARTICIPATED_ARCHIVED"
                        }
                    }]
            });
        }

        async function refresh() {
            try {
                $rootScope.spinner.show();

                await meetingWorkspaces.getMeetings();
                $scope.$applyAsync(function () {
                    vm.selectMode = false;
                    vm.selectedCards = [];
                    setMeetings();
                    calcHeight();
                });
            } finally {
                vm.loading = false;
                $rootScope.spinner.hide();
            }
        }

        function onDestroy() {
            window.removeEventListener("resize", calcHeight, true);
        }

        function onMeetingsRefresh() {
            $scope.$evalAsync(function () {
                vm.selectMode = false;
                vm.selectedCards = [];
                setMeetings();
            });
        }

        function deleteWithConfirmationDropdown(params) {
            deleteWithConfirmation(params.msgs, params.ev);
        }

        function calcHeight() {
            var height = $(".main-content-view").height();
            if (!height) {
                $timeout(calcHeight);
                return;
            }
            $(".st-team-workspaces.slide-section").css("min-height", height).css("max-height", height);
        }

        async function deleteWithConfirmation(msgs, ev) {
            if (!msgs) { return; }

            try {
                var confirm = $mdDialog.confirmDeletion()
                    .textContent($translate.instant('TEAM_WORKSPACE_CONFIRM_DELETE', { count: msgs.length }))
                    .targetEvent(ev);

                await $mdDialog.show(confirm);

                const oldCount = vm.meetings.length;
                var ids = [];
                var publicIdentifiers = [];
                for (var i = 0; i < msgs.length; i++) {
                    ids.push(msgs[i].id);
                    publicIdentifiers.push(msgs[i].publicIdentifier);
                }

                await meetingWorkspaces.deleteMeetings(ids);

                // Only need to look at owned meetings because you can't delete participated meetings.
                if (oldCount - ids.length < meetingWorkspaces.getFilteredOwnedMeetings().length) {
                    const confirm = $mdDialog.confirmDeletion()
                        .title()
                        .textContent($translate.instant("CONFIRMATIONS_UNABLE_TO_DELETE_MEETINGS"))
                        .ok($translate.instant("OK"))
                        .noWarn(true)
                        .hideCancel(true);
                    $mdDialog.show(confirm);
                }

                vm.refresh();
            } catch (err) {
                // No action on any exception here
            }

        }

        function doSearch(val) {
            vm.search = val;
        }

        function setMeetings() {
            switch (vm.selectedBranchData.key) {
                case "MY_MEETINGS":
                    vm.meetings = meetingWorkspaces.getFilteredOwnedMeetings(false);
                    break;
                case "MY_ARCHIVED":
                    vm.meetings = meetingWorkspaces.getFilteredOwnedMeetings(true);
                    break;
                case "PARTICIPATED_MEETINGS":
                    vm.meetings = meetingWorkspaces.getFilteredParticipatedMeetings(false);
                    break;
                case "PARTICIPATED_ARCHIVED":
                    vm.meetings = meetingWorkspaces.getFilteredParticipatedMeetings(true);
                    break;
            }

            if (!vm.meetings)
                return;
            vm.meetings.forEach((v) => { calculateDisplayedScheduleLines(v); });
        }

        function calculateDisplayedScheduleLines(meeting) {
            meeting.displayedScheduleLine1 = null;
            meeting.displayedScheduleLine2 = null;

            if (!meeting.scheduledStart)
                return;

            const startMeetingUtc = getUserTime(meeting.scheduledStart, meeting.startTzId).toDate();
            const endMeetingUtc = getUserTime(meeting.scheduledEnd, meeting.endTzId).toDate();
            meeting.displayedScheduleLine1 = `${$translate.instant("STARTS")} ${$filter("date")(startMeetingUtc, "longDate")} ${$filter("date")(startMeetingUtc, "shortTime")}`;
            meeting.displayedScheduleLine2 = `${$translate.instant("ENDS")} ${$filter("date")(endMeetingUtc, "longDate")} ${$filter("date")(endMeetingUtc, "shortTime")}`;

            // The code below was removed as a simplification to avoid user confusion.
            
            //    if (!meeting.scheduledEnd) {
            //        meeting.displayedScheduleLine1 = $filter("date")(startUtc, "longDate");
            //        meeting.displayedScheduleLine2 = $filter("date")(startUtc, "shortTime");
            //    }

            //    const endUtc = getUserTime(meeting.scheduledEnd).toDate();
            //    if (sameDay(startUtc, endUtc)) {
            //        meeting.displayedScheduleLine1 = $filter("date")(startUtc, "longDate");
            //        meeting.displayedScheduleLine2 = `${$filter("date")(startUtc, "shortTime")} - ${$filter("date")(endUtc, "shortTime")}`;
            //    }
            //    else {
            //        meeting.displayedScheduleLine1 = `${$translate.instant("STARTS")} ${$filter("date")(startUtc, "longDate")} ${$filter("date")(startUtc, "shortTime")}`;
            //        meeting.displayedScheduleLine2 = `${$translate.instant("ENDS")} ${$filter("date")(endUtc, "longDate")} ${$filter("date")(endUtc, "shortTime")}`;
            //    }

            //    function sameDay(d1, d2) {
            //        return d1.getFullYear() === d2.getFullYear() &&
            //            d1.getMonth() === d2.getMonth() &&
            //            d1.getDate() === d2.getDate();
            //    }

            function getUserTime(meetingTime, meetingTz) {
                let meeetingMomentUtc = moment.tz(meetingTime, meetingTz);
                // For some reason, even though the time zone is included in our meetingTime variable,
                // moment is reading it in as if it's UTC so adjusting these properties below so that
                // we're properly manipulating these time zones.
                meeetingMomentUtc._isUTC = false;
                meeetingMomentUtc._offset = 0;
                let userTzOffset = moment.tz(meeetingMomentUtc, vm.userTimeZone).utcOffset();
                let browserTzOffset = meeetingMomentUtc.utcOffset();
                let netTzOffset = userTzOffset - browserTzOffset;
                return moment.tz(meeetingMomentUtc, vm.userTimeZone).add(netTzOffset, "m");
            }
        }

        function setSelectMode(state) {
            vm.selectMode = state;
            vm.selectedCards = [];
        }

        function selectAll() {
            vm.selectedCards = [];
            $.each(vm.meetings,
                function (index, value) {
                    vm.selectedCards.push(value);
                });
        }

        function openCard(item, ev) {
            if (vm.selectMode) {
                var existingIndex = vm.selectedCards.indexOf(item);
                if (existingIndex === -1) {
                    vm.selectedCards.push(item);
                } else {
                    vm.selectedCards.splice(existingIndex, 1);
                }
            } else {
                var url = stSiteRoot + "interface/meeting#/" + item.fullId;
                window.open(url, item.fullId);
            }
        }

        function onMouseUp(item, ev) {
            if (!$scope.selectMode) {
                if (ev.which === 3 && vm.selectedBranchData.key === "MY_MEETINGS") {
                    ev.stopPropagation();
                    ev.preventDefault();
                    $scope.dropdownEvent = $.extend(true, {}, ev);
                    $scope.dropdownOptions = [];
                    $scope.dropdownOptions = $scope.dropdownOptions.concat([
                        { key: "copyText", click: function () { copyMeetingLink(item.fullId); }, translateKey: "COPY_LINK" },
                        { divider: true },
                        { key: "deleteItem", click: deleteWithConfirmationDropdown, params: { msgs: [item], ev: $scope.dropdownEvent }, translateKey: "DELETE" }
                    ]);
                    var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent"></st-context-menu>';
                    var element = $("#context-menu-area");
                    if (element) {
                        var elementCompiled = $compile(elementToCompile)($scope);
                        element.append(elementCompiled);
                    }
                }
            }
        }

        function copyMeetingLink(meetingId) {
            var link = window.location.href.substring(0, window.location.href.indexOf("/interface/")) + "/interface/meeting#/" + meetingId;
            copyMessage(link);
        }

        function copyMessage(message) {
            // Create a hidden textarea, fill it with the message, select and copy it, then remove it
            var el = document.createElement('textarea');
            el.value = message;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

            successHandling.report("COPIED_TO_CLIPBOARD");
        }

        function isCardSelected(item) {
            return vm.selectedCards.indexOf(item) !== -1;
        }

        function newMeeting(ev) {
            window.open(window.location.href.replace("/meetings", "/popout/meeting/new"),
                "", `resizable=1, ${popupService.dimensions.meeting}`);


            //$mdDialog.show({
            //	locals: { details: details },
            //	controller: "meetingModalController",
            //	controllerAs: "meetCtrl",
            //	templateUrl: "app/meetings/meeting.dlg.html",
            //	targetEvent: ev
            //})
            //	.then(vm.refresh, vm.refresh);
        }

        function onBranchChanged(branch) {
            vm.sortBy = 'title';
            vm.selectedBranchData = branch.data;
            setMeetings();
        }

        function onTreeStateChanged(event, data) {
            vm.treeExpanded = data.expanded;
            $timeout(function () { $(window).trigger('resize'); }, 250);
        }

        function unarchiveMeeting(meetings, ev) {
            if (!meetings)
                return;

            if (vm.selectedBranchData.key === "PARTICIPATED_MEETINGS" || vm.selectedBranchData.key === "MY_MEETINGS")
                return;

            const ids = [];
            for (let i = 0; i < meetings.length; i++) {
                ids.push(meetings[i].id);
            }

            meetingWorkspaces.unarchiveMeetings(ids)
                .then(
                    function () {
                        vm.refresh();
                    },
                    function () { });
        }
    }
})();