(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("contactViewController", contactViewController);

	function contactViewController($rootScope, $scope, $mdDialog, $timeout, $filter, $q, $stateParams, $http,
		coreData, coreDataContacts, coreDataSettings, authStorage, errorHandling, apiCategories, apiContactSources,
		errorMessageService, userDataService, preferencesStorage, toaster, $translate, themesService, popupService, claimsService, emailNavigation) {

		var vm = this;
		vm.ready = false;
		vm.isLoaded = false;
		vm.askBeforeLeave = true;
		vm.contactId = $stateParams.id;
		vm.sourceOwner = $stateParams.sourceOwner;
		vm.sourceId = $stateParams.sourceId;
		vm.isNew = vm.contactId === "new";
		vm.isGal = vm.sourceId === "gal";
		vm.categories = [];
		vm.sources = [];
		vm.displayNameChanged = false;
		vm.displayCompany = false;
		vm.displayFLNames = false;
		vm.removedAttachedFiles = [];

		vm.contactInfo = {
			sourcePermission: 8,
			phoneNumberList: [{ device: 0, phoneType: 0, number: "" }],
			emailList: [{ value: "" }]
		};
		vm.isDisabled = false;
		$scope.imageTimestamp = new Date().getTime();
		vm.emailList = [{ value: "" }];
		vm.phoneList = [{ device: 0, phoneType: 0, number: "" }];
		vm.isDomainAdmin = coreData.user.isDomainAdmin;
		vm.isMailingList = false;

		// Functions
		$scope.cancel = leavePage;
		$scope.delete = deleteThis;
		$scope.deleteWithConfirmation = deleteWithConfirmation;
		$scope.deleteWithConfirmationSingleContact = deleteWithConfirmationSingleContact;
		$scope.deleteWithConfirmationDropdown = deleteWithConfirmationDropdown;
		$scope.getAvatarSource = getAvatarSource;
		$scope.imagePreview = imagePreview;
		$scope.onSourceChanged = onSourceChanged;
		$scope.save = save;
		$scope.setForm = setForm;
		$scope.showDelete = showDelete;
		$scope.uploadAvatar = uploadAvatar;
		$scope.uploaderInit = uploaderInit;
		$scope.afContext = "user";
		vm.canDelete = canDelete;
		vm.canMove = canMove;
		vm.isOwner = isOwner;
		vm.deleteNoConfirmation = deleteNoConfirmation;
		vm.deletePromise = deletePromise;
		vm.deletePromiseSingleContact = deletePromiseSingleContact;
		vm.onFirstLastChanged = onFirstLastChanged;
		vm.onCompanyChanged = onCompanyChanged;
		vm.onDisplayNameChanged = onDisplayNameChanged;
		vm.emailDelete = emailDelete;
		vm.onEmailBlur = onEmailBlur;
		vm.onEmailChanged = onEmailChanged;
		vm.onPhoneBlur = onPhoneBlur;
		vm.onPhoneChanged = onPhoneChanged;
		vm.openNoteBody = openNoteBody;
		vm.phoneDelete = phoneDelete;
		vm.removeProfilePicture = removeProfilePicture;
		vm.removeAttachedFile = removeAttachedFile;
		vm.calculateCanSave = calculateCanSave;
		vm.hasSelectedCategory = hasSelectedCategory;
		vm.onCategoryChanged = onCategoryChanged;
		vm.onClearAllCategories = onClearAllCategories;
		vm.openManageCategoriesModal = openManageCategoriesModal;
		vm.categoryColorByName = categoryColorByName;
		vm.startCall = startCall;
		vm.sendEmail = sendEmail;

		activate();
		$scope.$on("$destroy", destroy);

		//////////////////////

		function activate() {
			themesService.ensureActivated();
			$rootScope.spinner.showInstant();

			var promises = [
				apiContactSources.getSources(),
				coreData.init()
			];

			if (!vm.isNew)
				promises.push(coreDataContacts.getContact(vm.sourceOwner, vm.sourceId, vm.contactId));

			$q
				.all(promises)
				.then(function (results) {
					vm.sources = results[0];

					var temp = $.grep(vm.sources, function (source) {
						return source.ownerUsername === userDataService.user.username && source.isPrimary;
					});
					$scope.defaultSource = temp.length > 0 ? temp[0] : vm.sources[0];
					$scope.currentSource = $scope.defaultSource;

					if (!vm.isNew) {
						setupEditContact(results[2]);
					}
					init();
					if ($scope.currentSource.ownerUsername) {
						apiCategories.categoryOwner = $scope.currentSource.ownerUsername;
					}
					vm.attachmentGuid = coreData.generateGuid();
					vm.attachedFiles =
						vm.contactInfo.attachedFiles ? JSON.parse(JSON.stringify(vm.contactInfo.attachedFiles)) : [];
					vm.categories = $scope.currentSource.ownerUsername ? setupCategories(apiCategories.getCategories(), vm.contactInfo.categories || []) : [];
				}, errorHandling.reportAndHideSpinner);

			window.onbeforeunload = function () {
				if (!vm.askBeforeLeave || !vm.userForm.$dirty)
					return undefined;
				vm.askBeforeLeave = false;
				return "";
			};

			userDataService.init();

			$scope.$on("categoriesUpdated", onCategoryListModified);
		}

		function destroy() {
			window.onbeforeunload = undefined;
		}

		function init() {
			var popoutData = authStorage.getPopoutData();
			if (popoutData) {
				vm.emailList = popoutData.primaryEmail ? [{ value: popoutData.primaryEmail }] : vm.emailList;
				vm.contactInfo.displayAs = popoutData.displayAs || vm.contactInfo.displayAs;
			}

			if (vm.isNew) {
				vm.emailList = $.grep(vm.emailList, function (email) { return email.value.length > 0; });
				vm.emailList.push({ value: "" });
				$scope.uploaderData = JSON.stringify({ email: undefined, id: 0, isDomainContact: false });
			}

			$scope.editingItem = true;
			vm.canSave = calculateCanSave();
			vm.isDisabled = !vm.canSave;
			vm.ready = true;

			$rootScope.spinner.hide();

			$timeout(function () {
				if (vm.userForm) {
					$rootScope.$broadcast("masonry:contentsChanged");
					if (popoutData && popoutData.activateSave) {
						vm.userForm.$setDirty();
					} else {
						vm.userForm.$setPristine();
						vm.userForm.$setUntouched();
					}
					vm.isLoaded = true;
				}
			});
		}

		function startCall(number) {
			document.location.href = "tel:" + number;
		}

		function sendEmail(emailAddress) {
			if (!emailAddress)
				return;
			var emails = [`"${vm.contactInfo.displayAs}" <${emailAddress}>`];
			var sessionId = new Date().getTime().toString();
			sessionStorage.setItem(sessionId, JSON.stringify({ emails: emails || [], vcards: [] }));

			const packet = emailNavigation.makeComposePacket({ sid: sessionId });
			const url = emailNavigation.getPopoutComposeUrl(packet);
			window.open(url, sessionId, "resizable=1, " + popupService.dimensions.email);
		}

		function setupEditContact(contact) {
			vm.contactInfo = contact;
			vm.isMailingList = contact.isMailingList;


			if (vm.contactInfo.displayAs) {
				vm.displayNameChanged = true;
			}
			$scope.birthdate = vm.contactInfo.birthDate ? moment(vm.contactInfo.birthDate).toDate() : "";
			$scope.weddingAnniversary = vm.contactInfo.weddingAnniversary ? moment(vm.contactInfo.weddingAnniversary).toDate() : "";
			vm.phoneList = vm.contactInfo.phoneNumberList || [{ phoneType: 0 }];
			for (var i = 0; i < vm.phoneList.length; ++i) {
				if (vm.phoneList[i].phoneType === 2 && vm.phoneList[i].device === 1) {
					vm.phoneList[i].phoneType = 9;
					vm.phoneList[i].device = 0;
				}
			}

			var temp = $.grep(vm.sources, function (source) {
				var result = false;
				result = vm.contactInfo.sourceId === source.itemID;
				if (!result)
					return result;
				result = vm.contactInfo.sourceId === "gal";
				if (result)
					return result;
				result = vm.contactInfo.sourceOwner && source.ownerUsername && vm.contactInfo.sourceOwner.toUpperCase() === source.ownerUsername.toUpperCase();
				if (result)
					return result;
				result = (vm.contactInfo.sourceId !== "gal") && ((vm.contactInfo.sourceOwner === null && source.ownerUsername === null) ||
					(vm.contactInfo.sourceOwner === '' && source.ownerUsername === '') ||
					(vm.contactInfo.sourceOwner === null && source.ownerUsername === '') ||
					(vm.contactInfo.sourceOwner === '' && source.ownerUsername === null));
				return result;
			});
			$scope.currentSource = temp.length > 0 ? temp[0] : vm.sources[0];
			temp = $.grep(vm.sources, function (source) {
				return source.access >= 8 || ($scope.currentSource && source.itemId == $scope.currentSource.itemId);
			});
			if (temp.length > 0)
				vm.sources = temp;

			if (!vm.phoneList || vm.phoneList.length === 0)
				vm.phoneList = [];
			else
				vm.phoneList = $.grep(vm.phoneList, function (phone) { return phone.number && phone.number.length > 0 });

			vm.phoneList.push({ device: 0, phoneType: 0, number: "" });

			if (!vm.contactInfo.emailAddressList || vm.contactInfo.emailAddressList === 0)
				vm.contactInfo.emailAddressList = [];
			vm.emailList = $.map(vm.contactInfo.emailAddressList, function (email) { return { value: email } });
			vm.emailList = $.grep(vm.emailList, function (email) { return email.value.length > 0; });
			vm.emailList.push({ value: "" });

			var source = vm.contactInfo.sourceId === "gal" ? vm.contactInfo.galAddress : vm.contactInfo.sourceOwner;
			$scope.uploaderData = JSON.stringify({ email: source, id: vm.contactInfo.id, isDomainContact: vm.contactInfo.sourceId === "gal" });

			// flagInfo
			vm.flagInfoString = null;
			if (vm.contactInfo.flagInfo) {
				const flagInfo = vm.contactInfo.flagInfo;
				vm.flagInfoString = generateFlagStatusString(flagInfo.request, flagInfo.startDate, flagInfo.dueDate, flagInfo.reminderTime);
			}
		}

		function generateFlagStatusString(flagText, startDate, dueDate, reminderTime) {
			let pieces = [];
			if (startDate)
				pieces.push($translate.instant("START_BY_DT", { dt: startDate }));
			if (dueDate)
				pieces.push($translate.instant("DUE_BY_DT", { dt: dueDate }));
			if (reminderTime)
				pieces.push($translate.instant("REMINDER_ON_DT", { dt: reminderTime }));
			if (pieces.length)
				return flagText + ": " + pieces.join(", ");
			else
				return flagText;
		}

		function hasSelectedCategory() {
			if (!vm.categories)
				return false;
			for (var i = 0; i < vm.categories.length; i++)
				if (vm.categories[i].selected) return true;
			return false;
		}

		function onCategoryChanged(cat) {
			if (cat)
				cat.selected = !cat.selected;
			vm.userForm.$setDirty();
		}

		function onClearAllCategories() {
			for (var i = 0; i < vm.categories.length; i++)
				vm.categories[i].selected = false;
			vm.userForm.$setDirty();
		}

		function openManageCategoriesModal(ev) {
			$mdDialog.show({
				controller: "manageCategoriesDialogController",
				controllerAs: "manageCategoriesCtrl",
				templateUrl: "app/shared/modals/manage.categories.dlg.html",
				targetEvent: ev
			});
		}

		function categoryColorByName(catName) {
			const cat = vm.categories.find(mcat => mcat.name.toUpperCase() === catName.toUpperCase());
			if (!cat || cat.colorIndex == -1)
				return null;
			const color = apiCategories.getCategoryColor(cat.colorIndex);
			if (!color || !color.rgb)
				return null;
			return color.rgb;

			function getCategoryByName(categoryName) {
				if (!categoryName) return null;
				var results = $.grep(vm.categories, (cat) => cat.name.toUpperCase() === categoryName.toUpperCase());
				return results.length > 0 ? results[0] : null;
			}
		}

		//// Save
		function validatePhoneList() {
			if (!vm.contactInfo.phoneNumberList)
				vm.contactInfo.phoneNumberList = [];

			vm.contactInfo.phoneNumberList = $.grep(vm.contactInfo.phoneNumberList, function (phoneNumber) { return phoneNumber.number.trim() !== ""; });
			for (var i = 0; i < vm.contactInfo.phoneNumberList.length; ++i) {
				if (vm.contactInfo.phoneNumberList[i].phoneType === 9) {
					vm.contactInfo.phoneNumberList[i].phoneType = 2;
					vm.contactInfo.phoneNumberList[i].device = 1;
				}
			}
		}

		function save(imageUrl) {
			$scope.editingItem = false;
			$scope.skipNavigateConfirm = true;
			vm.askBeforeLeave = false;
			if (vm.attachedFiles.some(x => x.isNew))
				vm.contactInfo.attachmentGuid = vm.attachmentGuid;
			vm.contactInfo.categories = $.grep(vm.categories || [], function (cat) { return cat.selected; });
			if (vm.isNew) {
				saveNew(imageUrl);
			} else {
				if (vm.toUploadBlob) {
					var isGal = vm.contactInfo.sourceId === "gal";
					var email = vm.contactInfo.sourceOwner ? vm.contactInfo.sourceOwner + "@" + userDataService.user.domain : null;
					if (isGal) {
						email = vm.contactInfo.galAddress;
					}
					uploadProfileImage(email, vm.contactInfo.id, isGal, vm.contactInfo.sourceId).then(saveEdit, saveEdit);
				} else {
					saveEdit();
				}
			}
		}

		function saveNew(imageUrl) {
			vm.contactInfo.phoneNumberList = [].concat(vm.phoneList);
			validatePhoneList();
			if (!vm.emailList)
				vm.emailList = [];

			vm.contactInfo.emailAddressList = $.map(vm.emailList, function (email) { return email.value });
			vm.contactInfo.emailAddressList.pop();
			vm.contactInfo.id = undefined;
			vm.contactInfo.birthDate = $scope.birthdate ? cleanseDate($scope.birthdate) : "";
			vm.contactInfo.weddingAnniversary = $scope.weddingAnniversary ? cleanseDate($scope.weddingAnniversary) : "";
			vm.contactInfo.sourceOwner = $scope.currentSource.ownerUsername;
			vm.contactInfo.sourceName = $scope.currentSource.displayName;
			vm.contactInfo.sourceId = $scope.currentSource.itemID;
			vm.contactInfo.source = $scope.currentSource;

			if (imageUrl)
				vm.contactInfo.image = imageUrl;

			coreDataContacts
				.addContact(vm.contactInfo)
				.then(function (success) {
					$scope.$broadcast("newContact", vm.contactInfo);
					vm.contactInfo.id = success.data.contacts[0].id;
					var email = vm.contactInfo.sourceOwner ? vm.contactInfo.sourceOwner + "@" + userDataService.user.domain : null;
					// Now that the contact has been created, upload the image
					if (vm.toUploadBlob) {
						uploadProfileImage(email, vm.contactInfo.id, false, vm.contactInfo.sourceId)
							.then(function () {
								leavePage();
							}, errorHandling.report);
					} else {
						leavePage();
					}
				}, function (failure) {
					errorMessageService.showErrorMessage(failure);
				});
		}

		// Process to always take the raw date without time of timezone.
		function cleanseDate(dateToCleanse) {
			try {
				return moment(dateToCleanse).startOf("day").format("YYYY-MM-DD");
			} catch (e) {
				return "";
			}
		}

		function saveEdit(imageUrl) {
			vm.contactInfo.phoneNumberList = [].concat(vm.phoneList);
			validatePhoneList();
			if (!vm.emailList)
				vm.emailList = [];
			else
				vm.emailList.pop();

			vm.contactInfo.emailAddressList = $.map(vm.emailList, function (email) { return email.value; });

			if ($scope.birthdate) {
				vm.contactInfo.birthDate = cleanseDate($scope.birthdate);
			}
			if ($scope.weddingAnniversary) {
				vm.contactInfo.weddingAnniversary = cleanseDate($scope.weddingAnniversary);
			}
			if (imageUrl)
				vm.contactInfo.image = imageUrl + "?" + ((Math.random() * 1000).toFixed(0));

			// Always up the email address with the primary email (if there's one set)
			if (vm.contactInfo.emailAddressList.length > 0)
				vm.contactInfo.email = vm.contactInfo.emailAddressList[0];

			const isMove = vm.contactInfo.sourceId !== $scope.currentSource.itemID;
			if (isMove) {
				const origContactId = vm.contactId;
				const origSourceId = vm.contactInfo.sourceId;
				const origSourceOwner = vm.contactInfo.sourceOwner;

				vm.contactInfo.id = undefined;
				vm.contactInfo.sourceOwner = $scope.currentSource.ownerUsername;
				vm.contactInfo.sourceName = $scope.currentSource.displayName;
				vm.contactInfo.sourceId = $scope.currentSource.itemID;
				vm.contactInfo.source = $scope.currentSource;
				vm.contactInfo.oldSourceId = origSourceId;
				vm.contactInfo.oldId = origContactId;
				vm.contactInfo.oldSourceOwner = origSourceOwner;

			}
			deleteRemovedAttachedFiles(vm.contactInfo.sourceId, vm.contactInfo.sourceOwner)
				.then(function () {
					return coreDataContacts.editContact(vm.contactInfo);
				}, errorMessageService.showErrorMessage)
				.then(function () {
					modifyContactCallback();
					leavePage();
				}, errorMessageService.showErrorMessage);

			function deleteRemovedAttachedFiles(sourceId, ownerEmail) {
				if (!vm.removedAttachedFiles || vm.removedAttachedFiles.length === 0) {
					var defer = $q.defer();
					defer.resolve();
					return defer.promise;
				}
				var source = vm.sources.filter(src => src.itemID === sourceId)[0];
				var removeParams = JSON.stringify({
					folderId: source.folderId,
					owner: ownerEmail,
					removeFileReferences: vm.removedAttachedFiles
				});
				return $http.post("~/api/v1/filestorage/remove-attached-file", removeParams);
			}

		}

		function uploadProfileImage(email, id, isGal, sourceId) {
			var defer = $q.defer();
			authStorage
				.getTokenWithPromise()
				.then(function (success) {
					var token = success;
					var formData = new FormData();
					formData.append("file", vm.toUploadBlob);
					if (email) formData.append("user", email);
					formData.append("contactID", id);
					formData.append("sourceId", sourceId);
					formData.append("domainContact", isGal ? isGal : false);
					$.ajax({
						url: coreData.baseUrl + "/api/v1/contacts/image",
						type: "POST",
						data: formData,
						contentType: false,
						processData: false,
						beforeSend: function (xhr) {
							if (token) {
								xhr.setRequestHeader("Authorization", "Bearer " + token);
							}
						}
					})
						.done(function (data) {
							vm.toUploadBlob = undefined;
							vm.contactInfo.image = data.image;
							defer.resolve();
						})
						.fail(function (failure) {
							defer.reject(failure);
						});
				}, function (failure) {
					defer.reject(failure);
				});
			return defer.promise;
		}

		//// Delete
		function deleteThis() {
			vm.deleteNoConfirmation([vm.contactInfo.id]);
		}

		function deleteNoConfirmation(selectedCards) {
			try {
				vm.deletePromise(selectedCards);
			}
			catch (err) {
				errorHandling.report(err.message);
				$rootScope.spinner.hide();
			}
		}

		function deleteWithConfirmationDropdown(params) {
			$scope.deleteWithConfirmation(params.card, params.event);
		}

		function deleteWithConfirmation(selectedCards, ev) {
			if (selectedCards.length > 0) {
				try {
					var confirm = $mdDialog.confirmDeletion()
						.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS", { items: selectedCards.length }))
						.targetEvent(ev);
					$mdDialog.show(confirm)
						.then(function () { vm.deletePromise(selectedCards); }, null);
				}
				catch (err) {
					errorHandling.report(err.message);
					$rootScope.spinner.hide();
				}
			} else {
				errorHandling.report("ERROR_NO_CARDS");
				$rootScope.spinner.hide();
			}
		}

		function deleteWithConfirmationSingleContact(id, sourceId, sourceOwner, ev) {
			if (id.length > 0, sourceId.length > 0) {
				try {
					var confirm = $mdDialog.confirmDeletion()
						.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS", { items: 1 }))
						.targetEvent(ev);
					$mdDialog
						.show(confirm)
						.then(function () { vm.deletePromiseSingleContact(id, sourceId, sourceOwner); }, function () { });
				}
				catch (err) {
					errorHandling.report(err.message);
					$rootScope.spinner.hide();
				}
			} else {
				errorHandling.report("ERROR_NO_CARDS");
				$rootScope.spinner.hide();
			}
		}

		function deletePromiseSingleContact(id, sourceId, sourceOwner) {
			$scope.editingItem = false;

			var oldCount = $scope.totalCount;
			$rootScope.spinner.show();
			coreDataContacts
				.removeSingleContact(id, sourceId, sourceOwner)
				.then(function () {
					if (oldCount - 1 < coreDataContacts.getContacts().length) {
						var confirm = $mdDialog.confirmDeletion()
							.title()
							.textContent($filter("translate")("CONFIRMATIONS_UNABLE_TO_DELETE"))
							.ok($filter("translate")("OK"))
							.noWarn(true)
							.hideCancel(true);
						$mdDialog.show(confirm);
					}

					leavePage();
					$rootScope.spinner.hide();
				}, function (failure) {
					$rootScope.spinner.hide();
					errorMessageService.showErrorMessage(failure);
				});
		}

		function deletePromise(selectedCards) {
			$scope.editingItem = false;

			var oldCount = $scope.totalCount;
			$rootScope.spinner.show();
			coreDataContacts
				.removeContacts(selectedCards)
				.then(function () {
					if (oldCount - selectedCards.length < coreDataContacts.getContacts().length) {
						var confirm = $mdDialog.confirmDeletion()
							.title()
							.textContent($filter("translate")("CONFIRMATIONS_UNABLE_TO_DELETE"))
							.ok($filter("translate")("OK"))
							.noWarn(true)
							.hideCancel(true);
						$mdDialog.show(confirm);
					}

					leavePage();
					$rootScope.spinner.hide();
				}, function (failure) {
					errorHandling.report(failure);
					$rootScope.spinner.hide();
				});
		}

		function showDelete(selectedCard) {
			if (selectedCard.sourceName !== "Global Address List" && selectedCard.sourcePermission > 4)
				return true;
			return false;
		}

		//// Cancel
		function leavePage() {
			$scope.skipNavigateConfirm = true;
			vm.askBeforeLeave = false;
			window.close();
		}

		function onFirstLastChanged() {
			if (!vm.displayNameChanged && !vm.displayCompany) {
				vm.displayFLNames = true;
				if (vm.contactInfo.firstName && vm.contactInfo.lastName) {
					vm.contactInfo.displayAs = vm.contactInfo.firstName + " " + vm.contactInfo.lastName;
				} else if (vm.contactInfo.firstName) {
					vm.contactInfo.displayAs = vm.contactInfo.firstName;
				} else if (vm.contactInfo.lastName) {
					vm.contactInfo.displayAs = vm.contactInfo.lastName;
				} else {
					vm.displayFLNames = false;
				}
			}
		}

		function onCompanyChanged() {
			if (!vm.displayNameChanged && vm.contactInfo.company && !vm.displayFLNames) {
				vm.displayCompany = true;
				vm.contactInfo.displayAs = vm.contactInfo.company;
			} else if (!vm.contactInfo.company) {
				vm.displayCompany = false;
			}
		}

		function onDisplayNameChanged() {
			if (!vm.contactInfo.displayAs) {
				vm.displayCompany = false;
				vm.displayFLNames = false;
				vm.displayNameChanged = false;
			} else {
				vm.displayNameChanged = true;
			}
		}

		//// Permissions

		function canDelete() {
			if (!vm.isNew && vm.contactInfo) {
				//return vm.contactInfo.galAddress === undefined || vm.contactInfo.galAddress.length < 0;
				return vm.contactInfo.sourcePermission >= 8;
			}
			else
				return false;
		}

		function canMove() {
			return !$scope.currentSource || ($scope.currentSource.itemID !== "gal" && $scope.currentSource.access >= 8);
		}

		function isOwner() {
			return $scope.currentSource && $scope.currentSource.ownerUsername === userDataService.user.username;
		}

		function calculateCanSave() {
			if ($scope.currentSource && $scope.currentSource.itemID === "gal")
				return vm.isDomainAdmin;
			if ($scope.currentSource)
				return $scope.currentSource.access >= 8;
			return true;
		}

		//// Phone Number List
		function onPhoneChanged(last, index, form) {
			var phone = form["phone" + index].$viewValue;
			if (phone && phone.length > 0 && last)
				vm.phoneList.push({ device: 0, phoneType: 0, number: "" });
		}

		function onPhoneBlur(last, index, form) {
			var phone = form["phone" + index].$viewValue;
			if ((!phone || phone.length === 0) && !last)
				vm.phoneList.splice(index, 1);
		}

		function phoneDelete(index, form) {
			form.$setDirty();
			vm.phoneList.splice(index, 1);
		}

		//// Email Address List
		function onEmailChanged(last, index, form) {
			var email = form["email" + index].$viewValue;

			vm.emailList[index] = { value: email };

			if (email && email.length > 0 && last)
				vm.emailList.push({ value: "" });
		}

		function onEmailBlur(last, index, form) {
			var email = form["email" + index].$viewValue;

			onEmailChanged(last, index, form);

			if ((!email || email.length === 0) && !last)
				vm.emailList.splice(index, 1);
		}

		function emailDelete(index, form) {
			form.$setDirty();
			vm.emailList.splice(index, 1);
		}

		//// Upload Avatar
		function uploaderInit(publicFunctions) {
			$scope.uploaderFunctions = publicFunctions;
		}

		function uploadAvatar() {
			coreDataContacts
				.uploadNewAvatar()
				.then(function () { }, errorHandling.report);
		}

		function imagePreview(imgSrc) {
			openUploadModal(imgSrc);
		}

		function openUploadModal(data) {

			var bytesSize = new Blob([data]).size;
			if (bytesSize > 5242880) {
				toaster.pop("error", $translate.instant("MAX_SIZE_EXCEEDED", { size: 5242880 }));
				$scope.$applyAsync();
			} else {
				$mdDialog
					.show({
						locals: { imageData: data, dimensions: { height: 128, width: 128 } },
						controller: "uploadCropperController",
						controllerAs: "upCtrl",
						templateUrl: "app/shared/upload-cropper.dlg.html"
					})
					.then(onUploadModalClose, function () { });
			}
		}

		function onUploadModalClose(result) {
			if (result.delete) {
				removeProfilePicture();
				return;
			}
			if (result.blob) {
				vm.toUploadBlob = result.blob;
				var reader = new window.FileReader();
				reader.readAsDataURL(result.blob);
				reader.onloadend = function () {
					vm.contactInfo.image = reader.result;
					$(".contact-img-preview img").attr("src", reader.result);
					vm.userForm.$setDirty();
					$scope.$applyAsync();
				}
			}
		}

		//// Edit Additional Info
		function openNoteBody(ev) {
			const locals = {
				name: "NOTES",
				text: vm.contactInfo.additionalInfo,
				readOnly: vm.isDisabled
			};
			if (vm.isGal) {
				locals.afContext = "domain";
				locals.afSourceType = "ap";
				locals.afSourceId1 = vm.contactId;
			} else if ($scope.currentSource.isDomainResource) {
				locals.afContext = "domainshare";
				locals.afSourceType = "cc";
				locals.afSourceId1 = undefined;
			} else {
				locals.afContext = "user";
				locals.afSourceType = "cc";
				locals.afSourceId1 = undefined;
			}

			$mdDialog.show({
				locals: locals,
				controller: "contactNoteEditController",
				controllerAs: "ctrl",
				templateUrl: "app/contacts/modals/contact-note-edit.dlg.html",
				targetEvent: ev,
				clickOutsideToClose: false
			})
				.then(function (modalSuccess) {
					if (vm.contactInfo.additionalInfo !== modalSuccess) {
						vm.contactInfo.additionalInfo = modalSuccess;
						vm.contactInfo.additionalInfoIsHtml = true;
						vm.userForm.$setDirty();
					}
				}, function () {
					// Cancel
				});
		}

		//// Get Avatars
		function getAvatarSource(imageUrl) {
			if (imageUrl) {
				return imageUrl + "&v=" + $scope.imageTimestamp;
			} else {
				return "img/ST_User_Cir.svg";
			}
		}

		function modifyContactCallback() {
			$scope.imageTimestamp = new Date().getTime();
		}

		// Category Functions
		function setupCategories(categories, selectedCategories) {
			if (!selectedCategories)
				selectedCategories = vm.contactInfo.categories;

			categories.forEach(function (cat) { cat.selected = false; });

			if (selectedCategories) {
				categories = selectedCategories.reduce(function (res, cat) {
					const masterCat = res.find(c => c.name === cat.name);
					if (masterCat) {
						masterCat.selected = cat.selected;
					} else if (cat.selected) {
						res.push({ name: cat.name, colorIndex: -1, master: false, translatedName: $filter("translate")(cat.name), selected: true });
					}
					return res;
				},
					categories);

			}

			return categories;
		}
		//// Category Functions
		function setCheckedCategories(categories, selectedCategories) {
			if (!selectedCategories)
				selectedCategories = vm.contactInfo.categories;

			categories.forEach(function (cat) { cat.selected = false; });

			if (selectedCategories) {
				selectedCategories.forEach(function (messageCat) {
					var found = false;
					for (var index = 0; index < categories.length; index++) {
						var masterCat = categories[index];
						if (masterCat.name === messageCat.name) {
							masterCat.selected = true;
							found = true;
							index = categories.length;
						}
					}

					if (!found) {
						const translated = $translate.instant(messageCat.name);
						const translatedName = $("<div>").html(translated).text(); // Translate HTML encodes the string, so we need to undo that

						categories.push({
							name: messageCat.name,
							colorIndex: -1,
							master: false,
							translatedName: translatedName,
							selected: true
						});
					}
				});
			}
		}

		function onCategoryListModified() {
			vm.categories = setupCategories(apiCategories.getCategories(), vm.categories);
		}

		function onSourceChanged() {
		}

		//// Misc Functions
		function setForm(form) {
			vm.userForm = form;
		}

		function removeProfilePicture(ev, form) {
			vm.contactInfo.image = "";
			$(".contact-img-preview img").attr("src", "");
			form.$setDirty();
		}

		function removeAttachedFile(attachment, ev) {
			if (vm.isDisabled || !attachment)
				return;
			if (attachment.isNew && typeof attachment.remove === "function") {
				attachment.remove();
				return;
			}

			vm.removedAttachedFiles.push(attachment.fileReference);
			vm.userForm.$setDirty();

		};
		vm.fileUploadedCallback = function () {
			vm.userForm.$setDirty();
		}
		$scope.$watch("currentSource", function (newvalue, oldvalue) {
			if (newvalue === oldvalue) return;
			if (newvalue.isDomainResource) {
				$scope.afContext = "domainshare";
				vm.categories = [];

			}
			else {
				apiCategories.categoryOwner = newvalue.ownerUsername;
				onCategoryListModified();
				$scope.afContext = "user";
			}
		});
	}

})();
